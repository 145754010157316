import axios from 'axios';
import { useCallback } from 'react';

import { API_BASE } from '../../consts/endpoints';

const useSanctumToken = () => {
    const getToken = useCallback(() =>
        axios.get(`${API_BASE}/sanctum/csrf-cookie`)
    );

    return {
        get: getToken,
    };
};

export default useSanctumToken;
