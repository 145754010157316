import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// use these constants to save us repeatedly typing out the same color classes
const SPINNER_BLACK = 'border-black-300';
const SPINNER_WHITE = 'border-white';

// classNames might be overkill here but I think its makes it easier to see the parameter separation
// parameters optional

const Spinner = ({ width, height, borderColor, borderSize, padding }) => (
    <div className={classNames(padding, 'flex justify-center')}>
        <div
            className={classNames(
                width,
                height,
                borderColor,
                borderSize,
                'border-solid !border-t-transparent rounded-full animate-spin'
            )}
        />
    </div>
);

Spinner.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    borderColor: PropTypes.string,
    borderSize: PropTypes.string,
    padding: PropTypes.string,
};

Spinner.defaultProps = {
    width: 'w-7',
    height: 'h-7',
    borderColor: SPINNER_BLACK,
    borderSize: 'border-4',
    padding: 'p-2',
};

export { Spinner, SPINNER_BLACK, SPINNER_WHITE };
