import { buildQueryString } from '../utils/buildQueryString';

const API_BASE =
    process.env.REACT_APP_API_BASE || 'http://local.thecyberhawkdev.com:8181';
const API_PREFIX = '/json-api/v1';
const API_PREFIX_V2 = '/json-api/v2';
const WEB_PREFIX = '/';

const API_PREFIX_CONSTRUCTION = `${API_PREFIX}/construction`;
const API_PREFIX_CONSTRUCTION_V2 = `${API_PREFIX_V2}/construction`;
const API_PREFIX_CORE = `${API_PREFIX}/core`;
const API_PREFIX_FINDALL = `${API_PREFIX}/findall`;
const API_PREFIX_GENERIC = `${API_PREFIX}/generic`;

const WEB_PREFIX_CONSTRUCTION = `${WEB_PREFIX}app`;
const WEB_PREFIX_OHL = `${WEB_PREFIX}ohl`;

const BUILD_API_URL = (endpoint = '', prefix = API_PREFIX) =>
    `${prefix}${endpoint}`;
const BUILD_WEB_URL = (endpoint = '', parameters = {}, prefix = WEB_PREFIX) => {
    const queryString = buildQueryString(parameters);
    return `${prefix}${endpoint}${
        Object.keys(parameters).length > 0 ? queryString : ''
    }`;
};

const CDN_ENDPOINT = process.env.REACT_APP_CDN_ENDPOINT;
const UPLOAD_ENDPOINT = process.env.REACT_APP_UPLOAD_ENDPOINT;

export {
    API_BASE,
    API_PREFIX,
    API_PREFIX_CONSTRUCTION,
    API_PREFIX_CONSTRUCTION_V2,
    API_PREFIX_CORE,
    API_PREFIX_FINDALL,
    API_PREFIX_GENERIC,
    BUILD_API_URL,
    BUILD_WEB_URL,
    CDN_ENDPOINT,
    UPLOAD_ENDPOINT,
    WEB_PREFIX,
    WEB_PREFIX_CONSTRUCTION,
    WEB_PREFIX_OHL,
};
