import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import useLocalStorageState from 'use-local-storage-state';

import userState from '../state/common/atoms/userState';

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [sessionIsValid, setSessionIsValid] = useLocalStorageState(
        'session.sessionIsValid',
        false
    );
    // last time session was refreshed
    const [lastSessionRefreshTime, setLastSessionRefreshTime] =
        useLocalStorageState('session.lastSessionRefreshTime', 0);
    // above states are all shared across tabs. this allows you to re-login in one tab and not have to login on the others
    // it also allows one tab to maintain the session record for all others

    const [userData, setUserData] = useRecoilState(userState);
    const userDataReset = useResetRecoilState(userState);
    const [authIsInitialized, setAuthIsInitialized] = useState(false);
    const [isBigScreen, setIsBigScreen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(null); // default null: not yet tried to get data

    useEffect(() => {
        if (
            typeof userData === 'undefined' ||
            typeof userData.isBigScreen === 'undefined'
        ) {
            return;
        }
        setIsBigScreen(userData.isBigScreen);
    }, [userData]);

    useEffect(() => {
        setAuthIsInitialized(true);

        return () => {
            setAuthIsInitialized(false);
        };
    }, []);

    const setUserAndToken = (user) => {
        setUserData(user);
    };

    const resetUserAndToken = () => {
        userDataReset();
    };

    const loginHandler = () => {
        setIsLoggedIn(true);
        setSessionIsValid(true);
        setLastSessionRefreshTime(Date.now());
    };

    const logoutHandler = () => {
        resetUserAndToken();
        setSessionIsValid(false);
        setIsLoggedIn(false);
    };

    const contextValue = useMemo(
        () => ({
            isLoggedIn,
            login: loginHandler,
            logout: logoutHandler,
            setUserAndToken,
            resetUserAndToken,
            sessionIsValid,
            setSessionIsValid,
            lastSessionRefreshTime,
            setLastSessionRefreshTime,
            isBigScreen,
            setIsBigScreen,
            authIsInitialized,
        }),
        [
            setUserAndToken,
            resetUserAndToken,
            sessionIsValid,
            setSessionIsValid,
            lastSessionRefreshTime,
            setLastSessionRefreshTime,
            isBigScreen,
            setIsBigScreen,
            authIsInitialized,
            isLoggedIn,
        ]
    );

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
};

export default AuthContext;
