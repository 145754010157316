import axios from 'axios';
import Cookies from 'js-cookie';
import React from 'react';
import { Provider } from 'use-http';

import { API_BASE } from './consts/endpoints';
import useSanctumToken from './hooks/Session/useSanctumToken';

axios.defaults.withCredentials = true;

// eslint-disable-next-line react/prop-types
const HttpProvider = ({ children }) => {
    const { get: getNewToken } = useSanctumToken();
    const providerOptions = {
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        },
        cachePolicy: 'no-cache',
        mode: 'cors',
        credentials: 'include',
        interceptors: {
            // every time we make an http request, this will run 1st before the request is made
            // url, path and route are supplied to the interceptor
            // request options can be modified and must be returned
            // eslint-disable-next-line no-unused-vars
            request: async ({ options, url, path, route }) => {
                if (options.method === 'GET') {
                    return options; // do not send csrf tokens with get requests
                }

                let token = Cookies.get('XSRF-TOKEN');
                if (!token) {
                    await getNewToken();
                    token = Cookies.get('XSRF-TOKEN');
                }

                // eslint-disable-next-line no-param-reassign
                options.headers['X-XSRF-TOKEN'] = token;
                return options;
            },
        },
    };

    return (
        <Provider url={API_BASE} options={providerOptions}>
            {children}
        </Provider>
    );
};

export default HttpProvider;
