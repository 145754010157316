import './index.css';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { MapProvider } from 'react-map-gl';
import { RecoilRoot } from 'recoil';

import OverlaySimple from './components/Loading/OverlaySimple';
import {
    SENTRY_DSN,
    SENTRY_ENVIRONMENT,
    SENTRY_TRACES_SAMPLE_RATE,
} from './consts/sentry';
import { AuthProvider } from './context/auth';
import HttpProvider from './HttpProvider';
import reportWebVitals from './reportWebVitals';

const App = lazy(() => import('./App'));
const SessionManager = lazy(() => import('./pages/SessionManager'));

const getApp = () => {
    /*
     * Allow a special route that bypasses the normal app
     * This route returns the session manager component so that the functionality
     * can be share with the legacy ihawk system without duplicating code, or messing
     * around with the build process
     */
    switch (window.location.pathname) {
        case '/session-manager':
            return <SessionManager />;
        default:
            return <App />;
    }
};

if (SENTRY_DSN !== '') {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment: SENTRY_ENVIRONMENT,
        integrations: [new BrowserTracing()],
        tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
    });
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <HttpProvider>
            <RecoilRoot>
                <AuthProvider>
                    <MapProvider>
                        <Suspense fallback={<OverlaySimple />}>
                            {getApp()}
                        </Suspense>
                    </MapProvider>
                </AuthProvider>
            </RecoilRoot>
        </HttpProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
