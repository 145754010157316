import queryString from 'query-string';

const buildQueryString = (params = {}) => {
    const stringifiedParams = queryString.stringify(params, {
        arrayFormat: 'bracket',
    });
    const urlParams = stringifiedParams.length ? `?${stringifiedParams}` : '';

    return urlParams;
};

const parseQueryString = (qString = '') => {
    const parsed = queryString.parse(qString, {
        arrayFormat: 'bracket',
    });
    return parsed;
};

export { buildQueryString, parseQueryString };
